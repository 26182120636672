import React, { useRef, useState } from "react";
import styles from "./tab.module.css";
import { MoneyLock } from "../../icons";
import { Headings } from "../../Layout";

export const financialPlans = [
  {
    id: 1,
    title: "Just Save",
    desc: (
      <>
        Just save is a flexible emergency savings plan, where you can start saving with a minimum of N2,000, and earn as
        high as 15% P.A.
        <br />
        Daily interest on average balance per day.
        <br />
        Minimum saving of N2000
        <br />
        Maximum of 3 withdrawals in one-month
      </>
    ),
  },
  {
    id: 2,
    title: "No-Access",
    desc: (
      <>
        Let that bulk cash earn you huge returns. Earn up to 18% when you save your money using No-Access.
        <br />
        Fixed for a minimum tenor of one month with no access until maturity.
        <br />
        Interest is paid at maturity with customer having the option to either rollover or liquidate their investment .
      </>
    ),
  },
  {
    id: 3,
    title: "Save for a Need",
    desc: (
      <>
        Save for a Need enables financial discipline and reward your efforts with higher interest rates.
        <br />
        Start saving towards a trip, school, rent, car, repairs and emergencies.
        <br />
        Save towards your goals starting with N5000.
        <br />
        Daily interest on average balance per day.
        <br />
        Minimum tenor is three months upon which withdrawals can be made four times in a one year cycle.
      </>
    ),
  },
];
export const investmentPlans = [
  {
    id: 1,
    title: "Inflation Protection Investment.",
    desc: (
      <>
        Protect your money against inflation. Save your money on BaigeWallet Inflation protection plan and earn value at
        the same time .
        <br />
        Minimum tenor is 6months-1year.
        <br />
        Interest paid upon maturity.
      </>
    ),
  },
  {
    id: 2,
    title: "Fund Investment.",
    desc: (
      <>
        The fund investment is created for risk tolerable investors for an opportunity to earn capital gains in addition
        to interest rate based on the performance of the collective investment portfolio.
        <br />
        Tenor 1 year (6 months minimum)
        <br />
        Interest on investment will be paid at maturity where Capital gains is earned based on fund contribution.
      </>
    ),
  },
];

const OfferHeight = ({ plan }: { plan: any }) => {
  const plans = useRef(null);
  React.useLayoutEffect(() => {
    let iconWrapper = document.getElementsByClassName("plans");
    plans.current &&
      (plans.current as any)?.style.setProperty(
        "--ht",
        (iconWrapper[2] as any)?.offsetHeight ? `${(iconWrapper[2] as any)?.clientHeight}px` : "100%"
      );
  }, []);

  return (
    <div className={`${styles.plan} plans`} ref={plans}>
      <div className={styles.plan_icon}>
        <MoneyLock />
      </div>
      <h2 className={styles.plan_Title}>{plan.title}</h2>
      <p className={styles.plan_desc}>{plan.desc}</p>
    </div>
  );
};

const FinancialPlans = () => {
  const types = [
    {
      title: (
        <>
          Build your savings with any of our three easy saving plans.
          <br />
          Earn as high as 19.5% when you save on BaigeWallet.
        </>
      ),
      type: "Savings Plan",
    },
    {
      title: (
        <>
          Secure Your Future With BaigeWallet.
          <br />
          Earn as high as 25% when you Invest on BaigeWallet.
        </>
      ),
      type: "Investment Plans",
    },
  ];
  const [active, setActive] = useState(types[0]);

  return (
    <section className={styles.plans}>
      <Headings>{active.title}</Headings>
      <div className={styles.buttonGroup}>
        {types.map((type, key) => (
          <button
            className={`${styles.tab} ${active.type === type.type ? `${styles.active}` : ""}`}
            key={key}
            onClick={() => setActive(type)}
          >
            {type.type}
          </button>
        ))}
      </div>

      {/* THE DUMMY DATA FOR THE TABS(INVESTMENT) TO BE ADDED AFTERWARDS   */}
      <React.Fragment>
        {active.type === types[0].type ? (
          <div className={styles.plansTab}>
            {financialPlans.map((plan) => (
              <OfferHeight plan={plan} key={plan.id} />
            ))}
          </div>
        ) : (
          <div className={styles.plansTab}>
            {investmentPlans.map((plan) => (
              <div key={plan.id} className={styles.plan}>
                <div className={styles.plan_icon}>
                  <MoneyLock />
                </div>
                <h2 className={styles.plan_Title}>{plan.title}</h2>
                <p className={styles.plan_desc}>{plan.desc}</p>
              </div>
            ))}
          </div>
        )}
      </React.Fragment>

      <p className={styles.more}>Swipe to see more</p>
    </section>
  );
};

export { FinancialPlans };
